@font-face {
  font-family: 'Mulish';
  src:
    local(''),
    url('/assets/fonts/mulish-v12-vietnamese_latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/assets/fonts/mulish-v12-vietnamese_latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'PlusJakartaSans';
  src:
    local(''),
    url('/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf')
      format('truetype'),
    url('/assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype'),
    url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src:
    local('Inter-Thin'),
    url('/assets/fonts/Inter/static/Inter-Thin.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src:
    local('Inter-ExtraLight'),
    url('/assets/fonts/Inter/static/Inter-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src:
    local('Inter-Light'),
    url('/assets/fonts/Inter/static/Inter-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local('Inter-Regular'),
    url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400 600;
  src:
    local('Inter-Italic'),
    url('/assets/fonts/Inter/static/Inter-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700 900;
  src:
    local('Inter-Bold-Italic'),
    url('/assets/fonts/Inter/static/Inter-Bold-Italic-700.otf')
      format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local('Inter-Medium'),
    url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    local('Inter-Semibold'),
    url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    local('Inter-Bold'),
    url('/assets/fonts/Inter/static/Inter-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src:
    local('Inter-ExtraBold'),
    url('/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src:
    local('Inter-Black'),
    url('/assets/fonts/Inter/static/Inter-Black.ttf') format('opentype');
}

%font-default {
  font-family: 'Inter', sans-serif;
}
* {
  @extend %font-default;
}
::-webkit-input-placeholder {
  @extend %font-default;
}

:-ms-input-placeholder {
  @extend %font-default;
}

:-moz-placeholder {
  @extend %font-default;
}

::-moz-placeholder {
  @extend %font-default;
}
.title {
  @apply font-plusJakartaSans text-[16px] font-semibold leading-6 tracking-[0.02em];
}
.body1 {
  @apply font-plusJakartaSans text-[16px] font-normal leading-6 tracking-[0.02em];
}
.body2 {
  @apply font-plusJakartaSans text-[14px] font-normal leading-5 tracking-[0.02em];
}
