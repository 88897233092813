.border-box {
  @apply ml-1 mr-1
  p-1
  flex flex-row
  text-blue-700
  rounded border border-solid border-blue-50
  justify-center;
  &--basic {
    @apply border-gray-100 p-0
  }
}

.simple-border-box {
  @apply p-1
  rounded border border-solid border-gray-100;
}

.simple-border-fit {
  @apply rounded border border-solid border-gray-100;
}
