/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* SCSS partials */
@import 'theme/scss/core-global.scss';
.t-confirm-dialog .p-confirm-dialog {
  min-width: 350px;
  .p-confirm-dialog-message {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
