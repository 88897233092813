.write-vertical-rl{
  writing-mode: vertical-rl;
}

.write-rotate-90 {
  transform: rotate(90deg);
}

.write-rotate-180 {
  transform: rotate(180deg);
}

.write-rotate-270 {
  transform: rotate(270deg);
}
